import { Component, OnInit } from '@angular/core';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { environment } from 'environments/environment';
//set lang
import { CoreTranslationService } from "@core/services/translation.service";
import { locale as english } from "app/layout/components/navbar/i18n/en";
import { locale as thai } from "app/layout/components/navbar/i18n/th";
import { TranslateService } from "@ngx-translate/core";
// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public notiCount:any;
  public imgPath = environment.imagePath+'/user/image/'
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private _coreTranslationService: CoreTranslationService,
    private translate: TranslateService,
    ) {
      this._coreTranslationService.translate(thai, english);
    }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
      this.notiCount = res.length;
      console.log('notification',this.notifications,this.notiCount,this.imgPath)
    });
  }
}
